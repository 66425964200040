import React from 'react'

const Context = React.createContext<boolean>(false)

export const IsCategoryPdpRouteProvider = ({ children, isCategoryPdpRoute = true }: { children: React.ReactNode, isCategoryPdpRoute: boolean }) => {
    return (
        <Context.Provider value={isCategoryPdpRoute}>
            {children}
        </Context.Provider>
    )
}

export const useIsCategoryPdpRoute = () => {
    return React.useContext(Context)
}
