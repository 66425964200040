module.exports = {
  "meta": {
    "index": {
      "title": " Mode große Größen | Wundercurves",
      "description": "Bei Wundercurves gibt es die beste Auswahl an Plus Size Mode. Wähle aus 4000 Marken bis Größe 68 Dein neues Lieblingsstück!"
    },
    "author": {},
    "category": {
      "title": "{category} in großen Größen online kaufen bei Wundercurves",
      "description": "{category} in großen Größen bei Wundercurves: Finde eine riesige Plus-Size-Auswahl an %s der beliebtesten Marken aus zahlreichen Online Shops."
    },
    "pdp": {
      "title": "{product} online kaufen bei Wundercurves",
      "description": "{product}"
    },
    "magazineArticle": {},
    "magazineList": {
      "title": "Dein Mode-Magazin für mittlere/große Größen",
      "description": "Dein Plus-Size-Magazin mit vielen Mode-Tipps & Inspirationen für mittlere/große Größen. Wir helfen Dir, Deine schönen Kurven am besten zur Geltung zu bringen!"
    }
  }
}