import config from 'config'
import * as React from 'react'
import { createSearchHelper } from 'utils/searchClient'
import * as t from 'modules/products/types'
import { navigate } from 'gatsby'
import { addRule } from 'redux-ruleset'

export default function useProduct() {
  return React.useContext(CTX)
}

const CTX = React.createContext<null | t.api.Hit>(null)

export function ProductProvider(props: { children: any }) {
  const [objectID, setObjectID] = React.useState(() => {
    if (typeof window === 'undefined') return ''
    const idMatch = (window.location.search + window.location.hash).match(/product-id=([^&#]+)/)
    if (!idMatch) return ''
    return idMatch[1]
  })
  const [product, setProduct] = React.useState<null | t.api.Hit>(null)

  React.useEffect(() => {
    if (!objectID) return

    const helper = createSearchHelper<t.api.Hit>(config.indexes.products, {
      disjunctiveFacets: ['objectID'],
    })
    helper.addDisjunctiveFacet('objectID', objectID)

    // Get the category ID from the URL
    const id = Object.keys(config.categories).find(
      (key) => config.categories[key].urlKey === window.location.pathname.replace(/\/$/, '')
    )
    // Set the context to the category ID
    const context = id ? 'category_' + id : undefined
    // Add the context as an override tag if it exists
    if (context) {
      helper.addOverrideTags([context])
    }

    helper.search().then(result => {
      if (result.hits.length === 0) {
        navigate('/404/')
      }
      else {
        setProduct({
          ...result.hits[0],
          curated: result.hits[0].curated || false
        })
      }
    })
  }, [objectID])

  React.useEffect(() => {
    addRule({
      id: 'pdp/GREP_PRODUCT_ID',
      target: 'navigation/LOCATION_CHANGE',
      output: '#pdp-fetch',
      consequence: (action) => {
        const idMatch = (action.payload.search + action.payload.hash).match(/product-id=([^&#]+)/)
        if (!idMatch) {
          setObjectID('')
          setProduct(null)
          return
        }
        setObjectID(idMatch[1])
      }
    })
  }, [])

  return (
    <CTX.Provider value={product}>
      {props.children}
    </CTX.Provider>
  )
}