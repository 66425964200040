import * as React from 'react'
import styled from 'styled-components'
import useProduct, { ProductProvider } from './useProduct'
import { ms } from 'modules/browser/const'
import Gallery from './Gallery'
import BuyBox from './BuyBox'
import formatPrice from 'utils/formatPrice'
import toPriceString from 'utils/toPriceString'
import StaticStory from './StaticStory'
import SimilarProducts from './SimilarProducts'
import { helmetJsonLdProp } from "react-schemaorg"
import { Product } from "schema-dts"
import { clickoutUrl, imageUrl, pdpLeafUrl, productNameSanitizer } from 'utils/jsonLdFormatter'
import Meta from 'theme/atoms/Meta'
import { useIsCategoryPdpRoute } from 'hooks/useIsCategoryPdpRoute'
import translations from 'config/translations'

export default function PDPContainer() {
  return (
    <ProductProvider>
      <PDP />
    </ProductProvider>
  )
}

function PDP() {
  const product = useProduct()
  const isCategoryPdpRoute = useIsCategoryPdpRoute()

  return (
    <>
      <StaticStory />

      {product && isCategoryPdpRoute && (
        <Meta
          type="website"
          pathname={typeof window !== 'undefined' ? window.location.pathname : ''}
          title={translations.meta.pdp.title.replace('{product}', productNameSanitizer(product.productName))}
          description={translations.meta.pdp.description.replace('{product}', product.productShortDescription || `${product.productName} - ${product.productManufacturerBrand}`)}
          canonicalUrl={product.curated ? pdpLeafUrl(product) : undefined}
          jsonLd={helmetJsonLdProp<Product>({
            "@context": "https://schema.org",
            "@type": "Product",
            name: product.productName,
            image: [
              imageUrl(product),
            ],
            brand: {
              "@type": "Brand",
              "name": product.productManufacturerBrand
            },
            manufacturer: {
              "@type": "Organization",
              "name": product.productManufacturerBrand
            },
            offers: {
              "@type": "AggregateOffer",
              offerCount: 1,
              highPrice: product.productPrice,
              lowPrice: product.productPrice,
              price: product.productPrice,
              priceCurrency: "EUR",
              availability: "https://schema.org/InStock",
              itemCondition: "https://schema.org/NewCondition",
              seller: {
                "@type": "Organization",
                "name": product.merchantName
              },
              url: product.curated ? pdpLeafUrl(product) : clickoutUrl(product)
            }
          })}
        />
      )}

      <BG>
        <Wrapper>
          <div className='gallery-wrapper'>
            <Gallery />
          </div>
          <div className='mobile-price'>
            <div className='price'>{product ? formatPrice(product.productPrice) : '...'}€</div>
            <div className='info'>inkl. Mwst. zzgl. {product ? toPriceString(product.shippingAndHandling) : ''} Versandkosten</div>
          </div>
          <BuyBox />
          <div className='similar-products-wrapper'>
            <SimilarProducts />
          </div>
        </Wrapper>
      </BG>
    </>
  )
}

const BG = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999; /* Lower than header (9999997) */
  padding: 15px;
  padding-top: 55px; /* Height of mobile header (40px) + consistent spacing (15px) */
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  > div {
    background: white;
    border-radius: 8px 8px 0 0;
    max-width: 1210px;
    width: 100%;
    min-height: calc(100vh - 55px); /* Adjusted to account for new header spacing */
    max-height: none;
    overflow-y: auto;
    padding: 15px 15px 30px 15px;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 40px;
      background: linear-gradient(to top, white 0%, rgba(255, 255, 255, 0) 100%);
      pointer-events: none;
      opacity: 0.8;
      
      @media (min-width: ${ms.TABLET}px) {
        display: none;
      }
    }
  }

  @media (min-width: ${ms.TABLET}px) {
    padding: 30px;
    padding-top: 70px; /* Height of desktop header (40px) + consistent spacing (30px) */
    z-index: 9999998; /* Higher than header on desktop */
    > div {
      border-radius: 8px;
      padding: 25px 25px 40px 25px;
      height: auto;
      min-height: 0;
      max-height: calc(95vh - 70px);
    }
  }

  @media (min-width: ${ms.CONTAINER}px) {
    > div {
      padding: 30px 30px 50px 30px;
    }
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  > .gallery-wrapper {
    position: relative;
    width: 100%;
    padding-bottom: 143%;

    > .Gallery {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
    }
  }

  > .mobile-price {
    background: white;
    display: flex;
    justify-content: space-between;
    padding: 10px 15px;

    > .price {
      color: #262626;
      font-weight: bold;
      font-family: "Open Sans";
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px;
      letter-spacing: 0.476px;
    }

    > .info {
      color: #262626;
      font-family: "Open Sans";
      font-size: 11px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: 0.327px;
    }
  }

  > .similar-products-wrapper {
    width: 100%;
    margin-top: 20px;
  }

  @media (min-width: ${ms.TABLET}px) {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 25px;

    > .gallery-wrapper {
      width: 220px;
      height: calc(220px * 1.43);
      padding-bottom: 0;
    }

    > .mobile-price {
      display: none;
    }

    > .BuyBox {
      flex: 1;
    }

    > .similar-products-wrapper {
      width: 100%;
      margin-top: 40px;
    }
  }

  @media (min-width: ${ms.LAPTOP}px) {
    flex-direction: row;
    gap: 25px;

    > .gallery-wrapper {
      width: 300px;
      height: calc(439px * 1.43);
    }
  }

  @media (min-width: ${ms.LAPTOP_L}px) {
    flex-direction: row;
    gap: 25px;

    > .gallery-wrapper {
      width: 500px;
      height: calc(500px * 1.43);
    }
  }
`