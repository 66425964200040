import * as React from 'react'
import styled from 'styled-components'
import useProduct from './useProduct'
import t from 'utils/typographie'

export default function Sizes () {
  const product = useProduct()

  const sortedSizes = React.useMemo(() => {
    if(!product) return []
    return product.wunderSizes.sort((a,b) => {
      const nA = parseInt(a)
      const nB = parseInt(b)
      if(!Number.isNaN(nA) && !Number.isNaN(nB)) return a < b ? -1 : 1
      return a.localeCompare(b)
    })
  }, [product?.wunderSizes])

  if(!product) return null

  // if(product.wunderColors.length <= 1) return null

  return (
    <Wrapper className='Sizes'>
      <div className='title'>Verfügbare Größen</div>
      <div className='items'>
        {sortedSizes.map(size => (
          <SizeItem className='item' key={size}>
            {size}
          </SizeItem>
        ))}
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  > .title {
    ${t([11, 2.4, 20], 'Raleway', 'bold')}
    margin-bottom: 10px;
    text-transform: uppercase;
  }

  > .items {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
  }
`

const SizeItem = styled.div`
  width: 36px;
  height: 36px;
  background: white;
  border: 1px solid #EEECED;
  border-radius: 50%;
  ${t([14, 1.2, 16], 'Raleway')};
  display: flex;
  align-items: center;
  justify-content: center;
`