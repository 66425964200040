import * as React from 'react'
import useProduct from '../useProduct'
import useProducts from './hooks/useProducts'
import ProductSlider from 'theme/molecules/ProductSlider'
import styled from 'styled-components'
import * as t from 'modules/products/types'

export default function SimilarProducts() {
  const product = useProduct()
  const products = useProducts(product as t.Product | null)

  if (!product || !products?.length) return null

  return (
    <Wrapper className="SimilarProducts">
      <ProductSlider
        title="Mehr davon"
        minProducts={4}
        products={products}
        listName='pdp-similar-products'
      />
    </Wrapper>
  )
}

const Wrapper = styled.div``