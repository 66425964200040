import * as React from 'react'
import styled from 'styled-components'
import useProduct from './useProduct'
import Story from 'storybook/Story'

export default function StaticStory() {
  const [story, setStory] = React.useState<any>(null)
  const product = useProduct()

  React.useEffect(() => {
    if(story || !product) return
    if(product.merchantName !== 'ORION') return // FIXME make this dynamic
    fetch('/static-stories/PDP_Related_Text.json').then(async res => {
      const content = await res.json()
      setStory(content)
    })
  }, [story, product])

  return (
    <Wrapper>
      <Story story={story} />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin-bottom: 20px;
`