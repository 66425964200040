import * as React from 'react'
import styled from 'styled-components'
import useProduct from './useProduct'
import { FiX } from 'react-icons/fi'
import { useMediaSize } from 'modules/browser'
import config from 'config'
import { navigate } from 'gatsby'

export default function Gallery () {
  const product = useProduct()
  const { activeMs } = useMediaSize('>=', 'LAPTOP_XL')
  const closePDP = () => {
    const url = new URL(window.location.href)
    const state = window.history.state
    
    // Remove from search params if present
    url.searchParams.delete('product-id')
    
    // Remove from hash if present
    let hash = url.hash
    hash = hash.replace(/[#&]?product-id=[^&]*/, '')
    // Clean up any leftover '&' at start of hash
    hash = hash.replace(/^[#&]+/, '#')
    // If hash is just '#', remove it entirely
    if (hash === '#') hash = ''
    url.hash = hash
    
    // Get the cleaned path and query string
    const cleanedPath = url.pathname + url.search + hash
    
    // Navigate to the cleaned URL
    navigate(cleanedPath, { replace: true })
  }
  return (
    <Wrapper className='Gallery'>
      {!product && <div className='placeholder' />}
      {product && <img src={getImageUrlWithTransformation(product, activeMs)} alt={product.productName}/>}
      <div className='back-wrapper' onClick={closePDP}>
        <FiX/>
      </div>
    </Wrapper>
  )
}

function getImageUrlWithTransformation(product, activeMs) {
  if (typeof window === 'undefined') return product.imageMediumURL
  const c = config.modules.cloudinary
  
  const dpr = window.devicePixelRatio
  const noCloudinaryImageFetchMerchants = config.blacklists.noCloudinaryImageFetchMerchants

  // default: build Cloudinary image fetch url
  let imageUrl = `${c.imageFetchUrl}${
    c.imageTransformations.pdp.mainImg[activeMs]
  },dpr_${dpr}/${encodeURIComponent(product.imageMediumURL)}`

  // backlisted merchant, use merchant image url, assure (force!) httpS/ SSL
  if (noCloudinaryImageFetchMerchants.indexOf(product.merchantName) !== -1) {
    imageUrl = product.imageMediumURL.replace('http://', 'https://')
  }
  return imageUrl
}


const Wrapper = styled.div`
  background: white;
  position: relative;

  > .back-wrapper {
    background: rgba(255, 255, 255, 0.85);
    position: absolute;
    cursor: pointer;
    top: 15px;
    left: 15px;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 36px;
    height: 36px;
    transition: background-color 0.2s ease;

    &:hover {
      background: rgba(255, 255, 255, 1);
    }

    > svg {
      color: #262626;
      font-size: 24px;
    }
  }

  > .placeholder {
    width: 100%;
    height: 100%;
    background: silver;
  }
  > .img-wrapper {
    width: 100%;

  }
  > img {
    width: 100%;
  }
`
