import * as React from 'react'
import styled from 'styled-components'
import { ms } from 'modules/browser/const'
import * as t from './types'
import ProductWidget from 'theme/molecules/ProductWidget'
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi'
import ty from 'utils/typographie'
import { useMediaSize } from 'modules/browser'

export type Props = {
  products: t.Product[]
  listName: string
  title?: string
  minProducts?: number
}

export default function ProductSlider(props: Props) {
  const firstRow = React.useRef<null | HTMLDivElement>(null)
  const entriesEl = React.useRef<null | HTMLDivElement>(null)
  const [scrollPosition, setScrollPosition] = React.useState(0)
  const minProducts = typeof props.minProducts === 'number' ? props.minProducts : 4
  const { activeMs } = useMediaSize('>=', 'LAPTOP')
  const sliderLengthMapping = {
    MOBILE_M: 3,
    MOBILE_L: 3,
    TABLET: 3,
    LAPTOP: 3,
    LAPTOP_L: 4,
    LAPTOP_XL: 5,
  }
  let sliderLength = sliderLengthMapping[activeMs]

  React.useEffect(() => {
    if (!firstRow.current || !entriesEl.current || !activeMs || activeMs === 'MOBILE_M' || activeMs === 'MOBILE_L' || activeMs === 'TABLET') return
    sliderLength = sliderLengthMapping[activeMs]
    const el = firstRow.current
    const style = window.getComputedStyle(el)
    const rect = el.getBoundingClientRect()
    const margin = parseInt(style.marginRight.replace('px', ''))
    entriesEl.current.scroll({
      left: scrollPosition * (rect.width + margin),
      behavior: 'smooth',
    })
  }, [scrollPosition, activeMs])

  if (props.products.length < minProducts)
    return <span data-cy-ctx="molecules/ProductSlider" data-cy-state="too-less-products" />

  return (
    <Wrapper className="ProductSlider" data-cy-ctx="molecules/ProductSlider">
      {props.title && (
        <>
          <div className="divider">
            <div data-cy-state="has-title" />
          </div>
          <h3>{props.title}</h3>
        </>
      )}
      <div className="slider-wrapper">
        <div className="list-wrapper" ref={entriesEl}>
          <div className="entries">
            {props.products.map((p, i) => (
              <div className="row" key={i} ref={i === 0 ? firstRow : undefined}>
                <ProductWidget listName={props.listName} index={i} product={p} />
              </div>
            ))}
          </div>
        </div>
        {activeMs !== 'MOBILE_M' && activeMs !== 'MOBILE_L' && activeMs !== 'TABLET' && scrollPosition > 0 && (
          <div
            className="slider left"
            data-cy-state="show-left-scroll-icon"
            data-cy-handle="left-scroll-icon"
            onClick={() => setScrollPosition((p) => p - 1)}
          >
            <FiChevronLeft />
          </div>
        )}
        {activeMs !== 'MOBILE_M' && activeMs !== 'MOBILE_L' && activeMs !== 'TABLET' && scrollPosition < props.products.length - sliderLength && (
          <div
            className="slider right"
            data-cy-state="show-right-scroll-icon"
            data-cy-handle="right-scroll-icon"
            onClick={() => setScrollPosition((p) => p + 1)}
          >
            <FiChevronRight />
          </div>
        )}
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin-bottom: 30px;

  @media (min-width: ${ms.TABLET}px) {
    margin-bottom: 60px;
  }

  > .divider {
    position: relative;
    width: 100%;
    height: 1px;
    background: #262626;
    margin: 7px 0;
    margin-bottom: 30px;

    > div {
      position: absolute;
      top: -7px;
      left: calc(50% - 7px);
      height: 15px;
      width: 15px;
      background: #262626;
      border-radius: 15px;
    }
  }

  > h3 {
    color: #262626;
    ${ty([28, 0.5, 38], 'Cormorant Garamond', '500')}
    text-align: center;
    margin-bottom: 30px;
  }

  > .slider-wrapper {
    position: relative;

    > .list-wrapper {
      width: 100%;
      padding: 0;
      overflow: visible;

      @media (min-width: ${ms.LAPTOP}px) {
        overflow: hidden;
      }

      > .entries {
        display: flex;
        flex-direction: column;
        gap: 30px;

        @media (min-width: ${ms.LAPTOP}px) {
          flex-direction: row;
          gap: 0;
        }

        > .row {
          ${ty([11, 2.4, 20], 'Raleway')}
          width: 100%;
          cursor: pointer;
          padding: 0;

          :global(.ProductWidget) {
            .image-wrapper {
              margin: 0;
              padding: 0;
              
              img {
                margin: 0;
                padding: 0;
                display: block;
                width: 100%;
                height: auto;
              }
            }
          }

          @media (min-width: ${ms.LAPTOP}px) {
            min-width: 30vw;
            max-width: 30vw;
            padding-right: 15px;
          }

          @media (min-width: ${ms.LAPTOP_L}px) {
            min-width: 23vw;
            max-width: 23vw;
          }

          @media (min-width: ${ms.LAPTOP_XL}px) {
            min-width: 220px;
            max-width: 220px;
          }
        }
      }
    }

    > .slider {
      position: absolute;
      cursor: pointer;
      bottom: 55%;
      width: 50px;
      height: 50px;
      background: rgb(248, 247, 248, 0.8);
      border: 1px solid #f8f7f8;
      border-radius: 50%;
      display: none;
      align-items: center;
      justify-content: center;

      @media (min-width: ${ms.LAPTOP}px) {
        display: flex;
      }

      &.left {
        left: -25px;
      }

      &.right {
        right: -25px;
      }

      > svg {
        font-size: 25px;
      }
    }
  }
`
