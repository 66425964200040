import { api } from 'modules/products'
import { dispatchEvent } from 'redux-ruleset'

export const CLICKOUT: 'PDP/CLICKOUT' = 'PDP/CLICKOUT'

/** @firescoutMockFn molecules-ProductWidget.action-click */
export const clickout = (product: api.Hit) =>
  dispatchEvent({
    type: CLICKOUT,
    payload: product,
  })

export type ClickOut = ReturnType<typeof clickout>

declare global {
  interface RulesetDispatchEvents {
    'containers/PDP': ClickOut
  }
}
