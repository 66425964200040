import * as t from 'modules/products/types'
import * as React from 'react'
import config from 'config'
import { createSearchHelper } from 'utils/searchClient'

export default function useSimilarProducts(product: t.Product | null) {
  const [products, setProducts] = React.useState<t.Product[]>([])

  React.useEffect(() => {
    if (!product) {
      setProducts([])
      return
    }
    fetchSimilarProducts(product).then(setProducts)
  }, [product])

  return products
}

/** @firescoutMockFn SimmilarProducts.fetchSimilarProducts */
async function fetchSimilarProducts(product: t.Product): Promise<t.Product[]> {
  const helper = createSearchHelper<t.Product>(config.indexes.products)

  // When using auto-embedding, you can directly set query_by to the 
  // auto-embedding field to do a semantic search on this field.
  // https://typesense.org/docs/28.0/api/vector-search.html#querying-for-similar-documents
  helper.setQuery(product.productName)
  helper.setQueryBy('vectors.similar')

  // Querying for similar documents
  // https://typesense.org/docs/28.0/api/vector-search.html#querying-for-similar-documents
  // helper.setVectorQuery('vectors.similar', product.objectID)

  const { hits } = await helper.search()
  // Filter out the current product from hits
  const filteredHits = hits.filter(hit => hit.objectID !== product.objectID)
  const ids = filteredHits.map((hit) => hit.objectID)

  const products = sortProducts(ids, filteredHits)
  return products
}

function sortProducts(ids: string[], products: t.Product[]): t.Product[] {
  const productsDict: Record<string, t.Product> = {}
  for (let product of products) productsDict[product.objectID] = product
  return ids.map((id) => productsDict[id]).filter(Boolean)
}