import * as React from 'react'
import styled from 'styled-components'
import useProduct from './useProduct'
import { ms } from 'modules/browser/const'
import t from 'utils/typographie'
import formatPrice from 'utils/formatPrice'
import Colors from './Colors'
import Sizes from './Sizes'
import { MdLocalShipping, MdFolderOpen } from "react-icons/md"
import config from 'config'
import Link from 'theme/atoms/Link'
import toPriceString from 'utils/toPriceString'
import { clickout } from './actions'
import { FiExternalLink } from 'react-icons/fi'
import { productNameSanitizer } from 'utils/jsonLdFormatter'

const { merchantClickout } = config.apiEndpoints

export default function BuyBox() {
  const product = useProduct()

  const categoryCrumbs = React.useMemo(() => {
    if (!product) return []
    
    const crumbs: Array<{ label: string; link: string }> = []
    const categoryPath: string[] = product?.wunderCategories ?? []

    const catDict: Record<string, string> = {}
    for (const key in config.categories) {
      const category = config.categories[key]
      catDict[category.categoryPath] = category.urlKey
    }

    for (let i = 0; i < categoryPath.length; i++) {
      const category = categoryPath[i]
      let path: string[] = []
      for (let k = 0; k <= i; k++) {
        path.push(categoryPath[k])
      }
      const pathKey = path.join(' > ')
      if (!catDict[pathKey]) continue

      crumbs.push({ label: category, link: catDict[pathKey] })
    }

    return crumbs
  }, [product])

  if (!product) return null

  return (
    <Wrapper className='BuyBox'>
      <div className='top'>
        <div className='brand'>{product.productManufacturerBrand}</div>

        <h1>{productNameSanitizer(product.productName)}</h1>

        {product.productShortDescription && (
          <div className='description'>
            {product.productShortDescription}
          </div>
        )}
      </div>

      <div className='center'>
        <div className='price-wrapper'>
          <div className='price'>{formatPrice(product.productPrice)}€</div>
          <div className='info'>inkl. Mwst. zzgl. {toPriceString(product.shippingAndHandling)} Versandkosten</div>
        </div>

        <Colors />

        <Sizes />

        <Link
          className='Link'
          to={'nofollow:' + merchantClickout + '/' + product.objectID + `?branch=${typeof window !== 'undefined' && window.abbranch || 'master'}`}
          onClick={() => clickout(product)}
        >
          <FiExternalLink />
          Bei {product.merchantName} kaufen
        </Link>
      </div>

      <div className='delivery'>
        <div className='title'><MdLocalShipping /> Lieferzeit:</div>
        <div className='content'>{product.deliveryTime}</div>
      </div>

      <div className='category'>
        <div className='title'><MdFolderOpen /> Kategorie:</div>
        <div className='content'>
          {categoryCrumbs.map((crumb, index) => (
            <React.Fragment key={crumb.label}>
              {index > 0 && <span className="separator"> &gt; </span>}
              <Link 
                to={crumb.link}
                className={index === categoryCrumbs.length - 1 ? 'current' : ''}
              >
                {crumb.label}
              </Link>
            </React.Fragment>
          ))}
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  background: #F8F7F8;
  padding: 15px;
  flex-direction: column;
  display: flex;
  gap: 30px;
  justify-content: space-between;

  @media (min-width: ${ms.TABLET}px) {
    background: white;
  }

  @media (min-width: ${ms.LAPTOP}px) {
    padding: 25px;
  }

  > .top {
    > .brand {
      ${t([11, 2.4, 25], 'Raleway')}
      margin-bottom: 2px;
      text-transform: uppercase;
    }
  
    > h1 {
      margin: 0;
      ${t([22, 0.84, 28], 'Cormorant Garamond')}
      margin-bottom: 15px;
    }

    > .description {
      ${t([11, 0.33, 18], 'Open Sans')}
    }

    @media (min-width: ${ms.LAPTOP}px) {
      > h1 {
        ${t([28, 0.5, 36], 'Cormorant Garamond')}
      }
    }
  }

  > .center {
    > .price-wrapper {
      display: none;
    }
  
    > .Link {
      ${t([11, 2.4, 25], 'Raleway', 'bold')}
      display: block;
      text-align: center;
      width: 100%;
      color: white;
      padding: 10px;
      background: #555;
      border: none;
      outline: none;
      margin-top: 20px;
      text-transform: uppercase;

      > svg {
        margin-right: 5px;
        transform: scale(1.5);
        margin-bottom: -1px;
      }
    }
  
    > .Colors {
      margin-top: 40px;
    }
  
    > .Colors {
      margin-top: 20px;
      margin-bottom: 20px;
    }
  
    @media (min-width: ${ms.LAPTOP}px) {  
      > .price-wrapper {
        display: block;
        margin-top: 45px;
  
        > .price {
          ${t([18, 0.54, 24], 'Open Sans', 'bold')}
        }
  
        > .info {
          ${t([11, 0.33, 18], 'Open Sans')}
        }
      }
    }
  }

  
  > .delivery, > .category {
    display: flex;
    gap: 5px;
    align-items: center;
    ${t([11, 0.33, 18], 'Open Sans')}

    svg {
      margin-bottom: -2px;
    }

    > .title {
      font-weight: bold;
      display: flex;
      align-items: center;
      gap: 3px;
    }
    
    > .content {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
    }
  }
  
  > .category {
    .separator {
      margin: 0 3px;
      color: #888;
    }
    
    a {
      color: #555;
      text-decoration: underline;
      transition: all 0.2s ease;
      
      &:hover {
        color: #000;
      }
      
      &.current {
        font-weight: bold;
        color: #000;
        position: relative;
        text-decoration: none;
        
        &:after {
          content: '';
          position: absolute;
          bottom: -2px;
          left: 0;
          width: 100%;
          height: 1px;
          background-color: #000;
        }
        
        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
`