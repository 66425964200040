import * as React from 'react'
import styled from 'styled-components'
import useProduct from './useProduct'
import t from 'utils/typographie'
import colorCodes from 'utils/colorCodes'

export default function Colors() {
  const product = useProduct()

  if (!product) return null

  // if(product.wunderColors.length <= 1) return null

  return (
    <Wrapper className='Colors'>
      <div className='title'>Verfügbare Farben</div>
      <div className='items'>
        <div className='current-color'>{product.productColor}</div>
        {product.wunderVariantColors
          .slice(0, 5)
          .map((color) => (colorCodes[color] ? <ColorItem key={color} color={color} /> : null))}
        {product.wunderVariantColors && product.wunderVariantColors.length > 5 && (
          <div className="more-colors" data-cy-state="h^as-more-colors">
            +
          </div>
        )}
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  > .title {
    ${t([11, 2.4, 20], 'Raleway', 'bold')}
    margin-bottom: 10px;
    text-transform: uppercase;
  }

  > .items {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    align-items: center;

    > .current-color {
      ${t([9, 2, 16], 'Raleway')}
    }
  }
`

const ColorItem = styled.div.attrs((p) => ({
  style: {
    background: colorCodes[p.color].bg,
  },
}))`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: ${(p) => (p.color.toLowerCase() === 'weiß' ? '1px solid grey' : 'none')};
`